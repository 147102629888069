.sticky-top {
  z-index: 9 !important;
}

.logo {
  width: 200px;
}

.ribbon {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #3c6365;
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px;
  z-index: 9;
  border-radius: 30px;
}

.block-info {
  cursor: pointer;
  .badge-secondary {
    border: 1px solid #6c757d !important;
    background-color: transparent !important;
    color: #6c757d !important;
  }
}
.block-info:hover,
.block-info:focus {
  .badge-secondary {
    background-color: #6c757d !important;
    color: #fff !important;
  }
}

.kSIiDU {
  margin: 0 auto !important;
  padding-top: 30px !important;
}

.cursor {
  cursor: pointer;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: #3c6365 !important;
  border-color: #3c6365 !important;
}

[data-toggle="collapse"].collapsed .if-not-collapsed {
  display: none;
}
[data-toggle="collapse"]:not(.collapsed) .if-collapsed {
  display: none;
}

.tab-more {
  color: #3c6365 !important;
  font-weight: bold;
  cursor: pointer;
}

.feature {
  height: 70px;
  text-align: center;
  display: table;
  width: 100%;
  .feature-item {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
}

.recharts-legend-wrapper {
  bottom: -15px !important;
}

.report-period-date-picker {
  display: flex;
  align-items: flex-end;
  label,
  .react-datepicker-wrapper {
    padding-right: 10px;
  }
}

.return-call-modal-link {
  display: inline-block;
  font-size: 13px;
  padding: 0 8px;
}

.header-nav .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  &.return-call {
    flex: 1;
    text-align: center;
  }
}

.channel-in-grid {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    margin: 0 12px;
  }
}

.file-button div[tabindex]:focus {
  outline: 0 none;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.card.mobile-mediaplan {
  border: 0 none;
  .card-header {
    $the_border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: $the_border;
    border-left: $the_border;
    border-right: $the_border;
    border-bottom: 0 none;
  }
  table {
    tr {
      th {
        width: 180px;
      }
      th,
      td {
        vertical-align: middle;
        .the-channel-block {
          display: flex;
          align-items: center;
          .the-icon {
            margin: 0 20px 0 3px;
          }
          .the-desc {
            flex: 1;
          }
        }
      }
    }
  }
}
.mediaplan-controls-layout {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  @media (max-width: 600px) {
    display: block;
  }
}
.mediaplan-controls-1 {
  flex: 1;
}
.mediaplan-controls-2 {
  padding-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.mediaplan-params-outer-list {
  display: block;
  margin: 0;
  padding: 0;
  @media (max-width: 992px) {
    display: flex;
    align-items: flex-start;
  }
}
.mediaplan-params-outer-list-item {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.mediaplan-params-list {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    display: block;
  }
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  .react-toggle-item {
    width: auto;
    display: flex;
    span {
      position: static;
    }
    margin-right: 20px;
    .react-toggle {
      height: 24px;
    }
  }
  margin: 0 0 10px;
  .label-element {
    margin-right: 15px;
  }
}
.reports-grid {
  min-width: 888px;
  & > div {
    padding: 12px;
    border-bottom: 1px solid #dfe2e6;
  }
  display: grid;
  overflow: visible;
  grid-template-columns: 3fr 12fr 10fr 5fr repeat(7, 10fr);
  grid-auto-rows: minmax(50px, auto);
  &.the-top-row {
    position: sticky;
    top: 0;
    background: white;
    & > div {
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      &.text-center {
        justify-content: center;
      }
      &.text-right {
        justify-content: flex-end;
      }
    }
  }
}
.bottom-spacing {
  padding-bottom: 3rem;
  @media (max-width: 992px) {
    padding-bottom: 6rem;
  }
}

.edit-internet {
  .the-pre-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .press-left,
  .press-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    z-index: 1;
    svg {
      width: 20px;
    }
  }
  .the-wrapper {
    position: relative;
    overflow: hidden;
  }
  .the-resources {
    position: absolute;
    left: 0;
    top: 0;
    transition: left 0.1s ease;
    margin: -20px;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    .card {
      margin: 20px;
      min-width: 300px;
      &,
      img {
        max-width: 300px;
      }
      .card-body {
        display: flex;
        flex-direction: column;
      }
      .card-text {
        flex: 1;
      }
    }
  }
  .the-calendar {
    margin: 0 auto;
    td {
      &,
      .day-button {
        width: 50px;
        height: 50px;
      }
    }
    th {
      text-align: center;
      height: 50px;
    }
    .btn-outline-primary:hover {
      background-color: rgba(#007bff, 0.25);
      color: #007bff;
    }
    .btn-outline-danger:hover {
      background-color: rgba(#dc3545, 0.25);
      color: #dc3545;
    }
  }
  margin-bottom: 250px;
}
.input-placeholder-text {
  line-height: 24px;
  min-height: 24px;
}
.input-placeholder {
  line-height: 38px;
  min-height: 38px;
}

#bannerUploadLabel {
  position: relative;
}

#bannerUpload {
  position: absolute;
  left: -9999px;
  top: 0;
}
