.no-top-padding {
  &,
  &.pt-1.pb-5 {
    padding-top: 0 !important;
  }
}
.time-result {
  height: 100%;

  .styles-time-table-wrapper {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    &.for-header {
      padding-bottom: 0;
      padding-top: 5px;
      overflow-x: auto;
    }
  }
  .styles-day {
    flex-grow: 1;
    background-color: #fff;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.04) 50%, transparent 50%);
  }

  .styles-day-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: #6c757d;
    color: #fff;
  }
  .styles-day-title:first-child {
    min-width: 80px !important;
  }

  .cursor-pointer {
    cursor: pointer;
    position: absolute;
  }

  .styles-hour {
    background-color: #6c757d;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    min-width: 80px !important;
  }

  .styles-event {
    position: absolute;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }
}

.react-calendar-timeline .rct-sidebar-header {
  background-color: #6c757d !important;
}
.react-calendar-timeline .rct-header .rct-label-group {
  background-color: #6c757d !important;
}
.rct-item-content {
  color: #fff;
  font-weight: bold;
  font-size: 11px !important;
  padding: 0 4px !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  text-align: center !important;
  font-weight: bold !important;
  font-size: 14px;
}
.rct-header {
  pointer-events: none;
}
.rct-item {
  background-color: #3c6365 !important;
  border: 1px solid #3c6365 !important;
  text-align: center;
}
.timeline {
  display: block;
  width: 100%;
  position: relative;
}
