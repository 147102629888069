@mixin footer-menu() {
  .footer {
    border-top: 1px solid #ccc;
    position: fixed !important;
    bottom: 0 !important;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 60px !important;
    line-height: 60px !important;
    z-index: 9;
    background: #4d515c !important;
    &,
    a {
      color: #ffffff;
      font-size: 14px;
    }
    a {
      background-image: none;
      text-decoration: underline;
    }
    a:hover,
    a:active {
      color: #ffffff;
    }
    a:focus {
      box-shadow: none !important;
    }
  }
}
html {
  position: relative !important;
  min-height: 100% !important;
  @include footer-menu();
}
.bootstrap {
  @include footer-menu();
}
