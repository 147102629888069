.notification-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}
.notification,
.notification-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.notification {
  padding: 15px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}
.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 700;
  margin: 0 0 5px;
}
.notification:focus,
.notification:hover {
  opacity: 1;
}
.notification-enter {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.notification-enter.notification-enter-active {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.notification-enter.notification-enter-active,
.notification-leave {
  visibility: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.notification-leave.notification-leave-active {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.notification-info {
  background-color: #3c6365;
}
.notification-success {
  background-color: #59a260;
}
.notification-warning {
  background-color: #f89406;
}
.notification-error {
  background-color: #dc3545;
}
